const STAGING = "prod"; // 'dev', 'prod', 'preview'

const KEY = {
  local: "zwzasj9gdq7nx31j6ba2apb0hu9ujd2s80pi4rzn83gb1taq",
  dev: "pxao634il7wi1e013smnnfpu19cj2f9a724bzis67z8jfyth",
  prod: "j144dfex44adjs8i4t7i6ovy7cmu1el2n7ge9ljy5x4ufiuw",
  preview: "3yv0et55ej26ht5xjhkeelo0a8cp64iq3onzhhcf9cbmau0s",
};

export const Keys = {
    EditorKey: KEY[STAGING] || ""
}